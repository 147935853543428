import React, { ReactElement } from "react";

import {
  LabeledJaarMaandInput,
  LabeledDateInput,
  LabeledPercentageInput,
  LabeledSelectInput,
  LabeledCurrencyInput
} from "adviesbox-shared";
import { LabelValuePairs } from "adviesbox-shared/utils/types";
import { HypotheekvormOptions } from "../../.generated/berekeningen-forms/berekeningen-formstypes";

type UitgangspuntenProps = {};

export const Uitgangspunten = (_props: UitgangspuntenProps): ReactElement => {
  const labelValueHypotheekvorm: LabelValuePairs = [
    { label: "Annuitair", value: HypotheekvormOptions.Annuitair },
    { label: "Lineair", value: HypotheekvormOptions.Lineair }
  ];

  return (
    <>
      <LabeledSelectInput
        options={labelValueHypotheekvorm}
        name="uitgangspunten.hypotheekVorm"
        caption="Hypotheekvorm"
      />
      <LabeledCurrencyInput name="uitgangspunten.hypotheekBedrag" caption="Hypotheekbedrag" verplicht/>
      <LabeledPercentageInput name="uitgangspunten.rentePercentage" caption="Rentepercentage" decimalen={2} verplicht/>
      <LabeledDateInput name="uitgangspunten.ingangsDatum" caption="Ingangsdatum" fieldSize="l" />
      <LabeledJaarMaandInput name="uitgangspunten.looptijd" caption="Looptijd" />
    </>
  );
};
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production") Uitgangspunten.displayName = "Uitgangspunten";

/* istanbul ignore file */
import { createSpanWithId } from "adviesbox-shared";
import { ReactElement } from "react";
import { Column } from "react-table-6";
import { bedragFormat } from "../shared/utils/currency";
import { decimalFormat } from "../shared/utils/decimal-format";
import { MaximaleHypotheekDataGridTemplates } from "./infra/maximale-hypotheek-schema";
import { castUitslag2Icon } from "./maximale-hypotheek-data-grid-icons";

export const getMaximaleHypotheekColumnTemplates = (nhg: boolean): { [key: string]: Column } => {
  return {
    [MaximaleHypotheekDataGridTemplates.uitslag]: {
      Header: "Max. hyp.",
      accessor: "uitslag",
      id: "iconColumn",
      Cell: (c): ReactElement => createSpanWithId(c.index, 0, castUitslag2Icon(c.original.uitslag)),
      width: 75
    },
    [MaximaleHypotheekDataGridTemplates.productnaam]: {
      Header: "Maatschappij",
      accessor: "productnaam",
      Cell: (c): ReactElement => createSpanWithId(c.index, 1, c.original.productnaam),
      width: 250
    },
    [MaximaleHypotheekDataGridTemplates.toetsrente]: {
      Header: nhg ? "NHG%" : "Toetsrente",
      id: "toetsrente",
      accessor: "toetsrente",
      Cell: (c): ReactElement => createSpanWithId(c.index, 2, `${decimalFormat(c.original.toetsrente, 3)}%`),
      width: 100
    },
    [MaximaleHypotheekDataGridTemplates.extraAflossing]: {
      Header: "Extra aflossing",
      id: "extra-aflossing",
      accessor: "extraAflossing",
      Cell: (c): ReactElement => createSpanWithId(c.index, 3, `${c.original.extraAflossing}`),
      width: 100
    },
    [MaximaleHypotheekDataGridTemplates.looptijd]: {
      Header: "Looptijd",
      id: "looptijd",
      accessor: "looptijd",
      Cell: (c): ReactElement => createSpanWithId(c.index, 4, `${c.original.looptijd} jr`),
      width: 100
    },
    [MaximaleHypotheekDataGridTemplates.maximaleHypotheek]: {
      Header: nhg ? "NHG Toetsing" : "Maximale hyp.",
      id: "maximaleHypotheek",
      accessor: "maximaleHypotheek",
      Cell: (c): ReactElement => createSpanWithId(c.index, 5, bedragFormat(c.original.maximaleHypotheek)),
      width: 100
    },
    [MaximaleHypotheekDataGridTemplates.maximaleHypotheekOnderpand]: {
      Header: nhg ? "NHG Onderpand/lening" : "Onderpand",
      id: "maximaleHypotheekOnderpand",
      accessor: "maximaleHypotheekOnderpand",
      Cell: (c): ReactElement => createSpanWithId(c.index, 6, bedragFormat(c.original.maximaleHypotheekOnderpand)),
      width: 100
    }
  };
};

import React, { ReactElement } from "react";

import { LabeledCurrencyInput, LabeledPercentageInput, LabeledNumberInput, LabeledSelectInput } from "adviesbox-shared";
import { LabelValuePairs } from "../../shared/types";
import { ToetsrenteBerekeningOptions } from "../../.generated/forms/formstypes";
import { useFormikContext } from "formik";
import { MaximaleHypotheekState } from "../infra/maximale-hypotheek-schema";
import { getMaximaleHypotheekTextResources } from "../infra/maximale-hypotheek-resources";

type ToetsrenteProps = {};

const BerekeningBasisOptions: LabelValuePairs = [
  {
    label: "Gewenste rentevastperiode",
    value: ToetsrenteBerekeningOptions.GewensteRentevastperiode
  },
  {
    label: "Specificatie toetsrente",
    value: ToetsrenteBerekeningOptions.SpecificatieToetsrente
  }
];
export const Toetsrente = (_props: ToetsrenteProps): ReactElement => {
  const { values } = useFormikContext<MaximaleHypotheekState>();
  return (
    <>
      <LabeledSelectInput
        name="toetsrente.berekeningOpBasis"
        options={BerekeningBasisOptions}
        caption="Berekening op basis van"
      />
      {values.toetsrente.berekeningOpBasis === ToetsrenteBerekeningOptions.GewensteRentevastperiode && (
        <LabeledNumberInput
          name="toetsrente.gewensteRentevastperiode"
          caption="Gewenste rentevastperiode"
          appendChildren="jaar"
          fieldSize="l"
        />
      )}
      {values.toetsrente.berekeningOpBasis === ToetsrenteBerekeningOptions.SpecificatieToetsrente && (
        <LabeledPercentageInput name="toetsrente.toetsrente" caption="Toetsrente" decimalen={2} />
      )}
      <h2 className="ml-3">Vermogen en kredieten</h2>
      <LabeledCurrencyInput name="toetsrente.vrijvermogenBedrag" caption="Vrij vermogen" />
      <LabeledCurrencyInput
        name="toetsrente.kredietToetslastBedrag"
        caption="Krediet toetslast"
        tooltip={getMaximaleHypotheekTextResources("KredietToelastTooltip")}
      />
    </>
  );
};
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production") Toetsrente.displayName = "Toetsrente";

import { Icon, LabeledBevestigingInput, LabeledNumberInput, ModalButton } from "adviesbox-shared";
import { useFormikContext } from "formik";
import React, { ReactElement } from "react";
import { getMaximaleHypotheekTextResources } from "../infra/maximale-hypotheek-resources";
import { MaximaleHypotheekState, ModalLeningdeelType } from "../infra/maximale-hypotheek-schema";
import MeeTeNemenLeningdelenModal from "../mee-te-nemen-leningdelen/mee-te-nemen-leningdelen-modal";

type LeningdelenUitHetVerledenProps = {};

export const LeningdelenUitHetVerleden = (_props: LeningdelenUitHetVerledenProps): ReactElement => {
  const { values, setFieldValue } = useFormikContext<MaximaleHypotheekState>();
  return (
    <>
      <LabeledBevestigingInput
        caption={"Leningdelen uit het verleden meenemen"}
        name={"leningdelenUitHetVerleden.meenemen"}
        tooltip={getMaximaleHypotheekTextResources("TooltipLeningdelenMeenemen")}
      />
      {values.leningdelenUitHetVerleden.meenemen && (
        <LabeledNumberInput
          fieldSize="l"
          name={"leningdelenUitHetVerleden.aantal"}
          caption={"Mee te nemen leningdelen"}
          readonly={true}
          appendChildren={
            <ModalButton
              parent="leningdelenUitHetVerleden"
              aria-label="Leningdelen uit het verleden"
              size={"lg"}
              content={<Icon name="specificatie" alt="Mee te nemen leningdelen" />}
            >
              <MeeTeNemenLeningdelenModal
                onSave={(data: ModalLeningdeelType[]): void => {
                  setFieldValue("leningdelenUitHetVerleden.leningdelen", data);
                  setFieldValue("leningdelenUitHetVerleden.aantal", data.length);
                }}
                data={values.leningdelenUitHetVerleden}
              />
            </ModalButton>
          }
        />
      )}
    </>
  );
};
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production")
  LeningdelenUitHetVerleden.displayName = "LeningdelenUitHetVerleden";

import React, { ReactElement } from "react";

import { LabeledCurrencyInput, LabeledNumberInput, LabeledBevestigingInput } from "adviesbox-shared";
import { ToetsnormInput } from "./toetsnorm";
import LabeledHdnKeuzelijst from "../../shared/components/hdn-keuzelijst/LabeledHdnKeuzelijst";

type UitgangspuntenProps = {};

export const Uitgangspunten = (_props: UitgangspuntenProps): ReactElement => {
  return (
    <>
      <LabeledBevestigingInput name="uitgangspunten.nhg" caption="NHG" />
      <ToetsnormInput name="uitgangspunten.nhgHypotheeknorm" caption="NHG/Hypotheeknorm" />
      <LabeledNumberInput
        name="uitgangspunten.looptijd"
        caption="Looptijd"
        appendChildren={<small>Jaar</small>}
        verplicht
        fieldSize="l"
      />
      <LabeledCurrencyInput name="uitgangspunten.marktwaardeBedrag" caption="Marktwaarde" />
      <LabeledCurrencyInput name="uitgangspunten.gewensteHypotheekBedrag" caption="Gewenste hypotheek" />
      <LabeledCurrencyInput name="uitgangspunten.box3gedeelteBedrag" caption="Box 3 gedeelte" />
      <LabeledCurrencyInput name="uitgangspunten.erfpachtcanonBedrag" caption="Erfpachtcanon" />
      <LabeledHdnKeuzelijst
        caption="Energielabel"
        name="uitgangspunten.energielabel"
        keuzelijst="EnergieKlasseType"
        berichtSoortType="AX"
        verplicht
        labelColSize={7}
      />
    </>
  );
};
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production") Uitgangspunten.displayName = "Uitgangspunten";
